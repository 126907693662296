import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@dispo-web/environments/environment';
import { reducers } from './general/store';
import { TokenInterceptor } from './general/interceptors/token.interceptor';
import { AntiCacheInterceptor } from './general/interceptors/anti-cache.interceptor';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './general/store/effects/auth.effect';
import { ErrorInterceptor } from './general/interceptors/error.interceptor';
import { ETagInterceptor } from './general/interceptors/eTag.interceptor';
import { ApiModule } from '@dispo-shared/open-api/api.module';
import { ApiModule as OrsApiModule } from '@dispo-shared/ors-api/api.module';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DevicesEffects } from './general/store/effects/devices.effect';
import { VehiclesEffects } from './general/store/effects/vehicles.effect';
import { UsersEffects } from './general/store/effects/users.effect';
import { TenantService } from './general/services/tenant.service';
import { SpaConfig } from '@dispo-shared/spa-config/spa-config';
import { ToastrModule } from 'ngx-toastr';
import { CustomToastsComponent } from './business-domains/funeral/shared/custom-toasts/custom-toasts.component';
import { forkJoin, map } from 'rxjs';
import { IconsComponent } from '@dispo-shared/shared-ui/icons/icons.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { GeoRouteService } from './general/services/geo-route/geo-route.service';
import { Router, RouteReuseStrategy } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { FuneralRouteReuseStrategy } from './business-domains/funeral/funeral-route-reuse-strategy';

function createTranslateLoader(prefixes: string[] = ['./assets/i18n/']) {
  return (http: HttpClient) => {
    return {
      getTranslation: (lang: string) =>
        forkJoin(
          prefixes.map((prefix) => {
            return new TranslateHttpLoader(http, prefix, '.json').getTranslation(lang);
          })
        ).pipe(
          map((translations) => {
            return translations.reduce((acc, translations2) => {
              return { ...acc, ...translations2 };
            }, {});
          })
        ),
    };
  };
}

export function initialise(translate: TranslateService, tenantService: TenantService): any {
  return async () => {
    // Supported languages
    translate.addLangs(['de', 'en']);
    // User german as default
    translate.setDefaultLang('de');

    // Set translation to browser language if we support it
    const browserLang = translate.getBrowserLang() ?? 'de';
    if (translate.langs.indexOf(browserLang) < 0) {
      translate.use('de');
    } else {
      translate.use(browserLang);
    }

    const translatePromise = new Promise<void>((resolve) => {
      translate.onLangChange.subscribe(() => {
        resolve();
      });
    });
    const tenantPromise = tenantService.loaded;

    return Promise.all([translatePromise, tenantPromise]);
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    MessagesModule,
    MessageModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([AuthEffects, DevicesEffects, VehiclesEffects, UsersEffects]),
    ButtonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ApiModule.forRoot({ rootUrl: SpaConfig.getApiBaseUrl() }),
    OrsApiModule.forRoot({ rootUrl: 'https://ors.geo.openresearch.com/ors' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader(['./assets/i18n/', './assets/i18n/shared-ui/']),
        deps: [HttpClient],
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    ToastrModule.forRoot({
      toastComponent: CustomToastsComponent,
      toastClass: 'toastr',
      closeButton: true,
      tapToDismiss: false,
    }),
    IconsComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initialise,
      deps: [TranslateService, TenantService],
      multi: true,
    },
    { provide: 'environment', useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ETagInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AntiCacheInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    Title,
    GeoRouteService,
    { provide: RouteReuseStrategy, useClass: FuneralRouteReuseStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
  }
}
