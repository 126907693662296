import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@dispo-web/environments/environment';
import { TenantsService } from '@dispo-shared/open-api/services/tenants.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { TenantFrontendConfig, TenantKeyResponse } from '@dispo-shared/open-api/models';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  public loaded!: Promise<boolean>;
  private promiseResolve: any;
  private readonly storeService: StoreService = inject(StoreService);
  public readonly tenantShortCode: string = window.location.host.split('.')[0].split(':')[0];
  constructor(
    private http: HttpClient,
    private tenantService: TenantsService,
    private authService: AuthService,
    private router: Router
  ) {
    this.loaded = new Promise((resolve) => {
      this.promiseResolve = resolve;
    });

    // let tenantShortCode = window.location.host.split('.')[0].split(':')[0];
    this.storeService.tenantShortCode.set(this.tenantShortCode);
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (
      !Object.prototype.hasOwnProperty.call(environment, 'tenantShortCode') &&
      this.tenantShortCode === 'localhost'
    ) {
      this.tenantShortCode = (environment as any).tenantShortCode;
    }

    const getTenantKey = this.tenantService.getTenantKeyByShortCode({
      short_code: this.tenantShortCode,
    });

    const getTenantConfig = this.tenantService.getTenantFrontendConfigByShortCode({
      short_code: this.tenantShortCode,
    });

    forkJoin([getTenantKey, getTenantConfig]).subscribe({
      next: ([keyResponse, configResponse]: [TenantKeyResponse, TenantFrontendConfig]) => {
        if (keyResponse) {
          const tenantKey = keyResponse.key;
          this.authService.setTenantKey(tenantKey);
        }
        if (configResponse) {
          if (configResponse.tenant_meta) {
            this.storeService.setTenantMeta(configResponse.tenant_meta);
          }

          if (configResponse.config) {
            this.storeService.setTenantConfig(configResponse.config as any);
          }
        }
        this.promiseResolve(true);
      },
      error: () => {
        console.error('Tenant not found');
        this.promiseResolve(false);
        if (window.location.pathname !== '/signup') {
          this.router.navigateByUrl('/tenant-not-found');
        }
      },
    });
  }

  /**
   * Checks if the given file url is exists in the app
   *
   * @param {string} url location of the file
   * @returns {Observable<boolean>} True or false whether the file exists or not
   */
  fileExists(url: string): Observable<boolean> {
    return this.http
      .get<boolean>(url, {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(
        map(() => {
          return true;
        }),
        catchError(() => {
          return of(false);
        })
      );
  }

  getServices(): Observable<{ key: string; title: string }[]> {
    return this.tenantService
      .getTenantMasterData({ tenant_short_code: this.tenantShortCode })
      .pipe(map((tenantData) => tenantData?.['master-data-items']?.[0]?.data?.service_types ?? []));
  }
}
