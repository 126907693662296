import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromAuthAction from '../actions/auth.action';
import * as fromUsersAction from '../actions/users.action';
import * as fromUsersReducer from '../reducers/users.reducer';
import * as fromDevicesAction from '../actions/devices.action';
import * as fromDevicesReducer from '../reducers/devices.reducer';
import * as fromVehiclesAction from '../actions/vehicles.action';
import * as fromVehiclesReducer from '../reducers/vehicles.reducer';
import * as fromRolesAction from '../actions/roles.action';
import * as fromRolesReducer from '../reducers/roles.reducer';
import { tap } from 'rxjs/operators';
import { NavigationBehaviorOptions, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { WebSocketsService } from '@dispo-web/app/general/services/web-sockets/web-sockets.service';
import { StoreService } from '@dispo-web/app/general/services/store.service';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class AuthEffects {
  private actions$: Actions = inject(Actions);
  private authService: AuthService = inject(AuthService);

  /**
   * A listener for login actions.
   * - Sets the given tokens to the local storage
   * - If the refresh value is true then it's navigate to the admin page
   * - It does not dispatches a new event
   */
  login$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromAuthAction.Login>(fromAuthAction.AuthActionTypes.Login),
        tap((action) => {
          localStorage.setItem(`dispo.access_token`, action.payload.tokens.access_token.value);
          localStorage.setItem(`dispo.refresh_token`, action.payload.tokens.refresh_token.value);
          localStorage.setItem(`dispo.access_token_id`, action.payload.tokens.id);
          if (action.payload.refresh === true) {
            let route;
            if (this.authService.lastUrlBeforeLogin) {
              route = this.authService.lastUrlBeforeLogin;
              this.authService.lastUrlBeforeLogin = null;
            } else {
              route = this.storeService.defaultRoute();
            }

            this.router.navigateByUrl(route);
          }
        })
      );
    },
    { dispatch: false }
  );

  /**
   * A listener for logout actions.
   * - Removes the stored tokens from the local storage
   * - Stops the websocket connection
   * - Redirects to the starting page then resets the store
   */
  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromAuthAction.Logout>(fromAuthAction.AuthActionTypes.Logout),
        tap(() => {
          localStorage.removeItem(`dispo.access_token`);
          localStorage.removeItem(`dispo.refresh_token`);
          localStorage.removeItem(`dispo.access_token_id`);
          this.webSocketService.disconnect();
          window.location.reload();
          // this.router.navigateByUrl('/').then(() => {
          //   this.usersStore.dispatch(new fromUsersAction.ResetUsers());
          //   this.devicesStore.dispatch(new fromDevicesAction.ResetDevices());
          //   this.vehiclesStore.dispatch(new fromVehiclesAction.ResetVehicles());
          //   this.usersStore.dispatch(new fromUsersAction.ResetUsers());
          //   this.rolesStore.dispatch(new fromRolesAction.ResetRoles());
          //   // window.location.reload();
          // });
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private router: Router,
    private usersStore: Store<fromUsersReducer.UsersState>,
    private devicesStore: Store<fromDevicesReducer.DevicesState>,
    private vehiclesStore: Store<fromVehiclesReducer.VehiclesState>,
    private rolesStore: Store<fromRolesReducer.RolesState>,
    private webSocketService: WebSocketsService,
    private storeService: StoreService
  ) {}
}
