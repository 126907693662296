import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromDevicesAction from '../actions/devices.action';
import { tap } from 'rxjs/operators';
import { VehiclesStoreService } from '@dispo-web/app/business-domains/funeral/services/vehicles-store.service';

@Injectable()
export class DevicesEffects {
  private actions$: Actions = inject(Actions);

  public update$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<fromDevicesAction.UpdateDevice>(fromDevicesAction.DevicesActionTypes.UpdateDevice),
        tap((action) => {
          if (action.payload.oldDevice) {
            if (action.payload.device.active_vehicle_association?.vehicle_id) {
              // Update new association
              this.vehiclesStoreService.getVehicleById(
                action.payload.device.active_vehicle_association.vehicle_id
              );
            }
            if (action.payload.oldDevice?.active_vehicle_association?.vehicle_id) {
              // Remove old association
              this.vehiclesStoreService.getVehicleById(
                action.payload.oldDevice.active_vehicle_association.vehicle_id
              );
            }
          }
        })
      );
    },
    { dispatch: false }
  );

  constructor(private vehiclesStoreService: VehiclesStoreService) {}
}
