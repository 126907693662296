import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[toastr-component]',
  templateUrl: './custom-toasts.component.html',
  styleUrls: ['./custom-toasts.component.scss'],
})
export class CustomToastsComponent extends Toast {
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private router: Router
  ) {
    super(toastrService, toastPackage);
  }

  navigateTo(url: string, id: string | number): void {
    if (!this.router.url.startsWith(`${url}${id}`)) {
      this.router.navigate([`${url}${id}`]);
    }
    this.remove();
  }
}
