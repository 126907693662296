export abstract class SpaConfig {
  public apiBaseUrl: string;

  public static getApiBaseUrl(): string {
    try {
      const apiBaseUrl = (window as any).spaConfig.endpoints.api;
      return apiBaseUrl;
    } catch (error) {
      console.error('SpaConfig error', error);
      return 'https://dispo-xyz-api.prod.dispo.xyz';
    }
  }
}
