import { Injectable, Signal, WritableSignal, computed, signal } from '@angular/core';
import { TenantMeta } from '@dispo-shared/open-api/models';

interface TenantConfig {
  phone_number_login_enabled: boolean;
  logo_default: string;
  favicon: string;
  colors?: { [key: string]: string };
}

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  // Tenant Meta
  private _tenantMeta: WritableSignal<TenantMeta> = signal({});
  public tenantMeta: Signal<TenantMeta> = this._tenantMeta.asReadonly();
  // Tenant Config
  private _tenantConfig: WritableSignal<TenantConfig> = signal({
    phone_number_login_enabled: true,
    logo_default: 'assets/img/logo_default.png',
    favicon: 'favicon_default.ico',
  });
  private _appVersion: WritableSignal<string> = signal(null);

  public tenantConfig: Signal<TenantConfig> = this._tenantConfig.asReadonly();
  // Tenant Short Code
  public tenantShortCode: WritableSignal<string> = signal(null);
  // Default Route
  public defaultRoute: Signal<string> = computed(() =>
    this.tenantMeta().type === 'ROADSIDE_ASSISTANCE' ? '/rsa' : '/fnrl'
  );
  public appVersion: Signal<string> = this._appVersion.asReadonly();

  setTenantMeta(tenantMeta: TenantMeta): void {
    this._tenantMeta.set(tenantMeta);
  }

  setAppVersion(version: string): void {
    this._appVersion.set(version);
  }

  setTenantConfig(tenantConfig: TenantConfig): void {
    const { phone_number_login_enabled, logo_default, favicon, colors } = tenantConfig ?? {};
    this._tenantConfig.set({
      ...this._tenantConfig(),
      ...(phone_number_login_enabled != null && { phone_number_login_enabled }),
      ...(logo_default && { logo_default }),
      ...(favicon && { favicon }),
      ...(colors && { colors }),
    });
  }
}
