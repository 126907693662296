import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { EnvHelper } from '@dispo-shared/shared-ui/helper/env-helper/env-helper';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const tenantShortCode: string = window.location.host.split('.')?.[0]?.split(':')?.[0];

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://52cf22004f614edb1ba13bce57e67f7c@o684811.ingest.us.sentry.io/4507216379641856',
  environment: EnvHelper.getWebEnv(),
  enabled: environment.production,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  initialScope: {
    tags: { tenant: tenantShortCode },
  },
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
