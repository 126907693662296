import { Injectable, Signal, WritableSignal, inject, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  UsernameLoginRequest,
  LoginResponse,
  OneTimeCodeLoginRequest,
  User,
} from '../interfaces/auth.interface';
import { Status } from '../interfaces/status.enum';
import { Login, Logout, SetStatus } from '../store/actions/auth.action';
import { AuthState } from '../store/reducers/auth.reducer';
import { AuthUserResponse, Tenant } from '@dispo-shared/open-api/models';
import { AuthenticationService } from '@dispo-shared/open-api/services';
import { GrantsService } from './grants.service';
import { jwtDecode } from 'jwt-decode';

export interface JwtPayload {
  jti: string;
  iat: number;
  nbf: number;
  exp: number;
  sub: string;
  iss: string;
  principal: string;
}

export interface JwtUserPrincipal {
  user: User;
  grants: string[];
  branchAccessAllGrants: string[];
  roles: string[];
  tenant: Tenant;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly grantsService: GrantsService = inject(GrantsService);

  private tenantShortCode: string | null = null;
  private tenantKey: string | null = null;
  private _accessToken: WritableSignal<string | null> = signal(null);
  public accessToken: Signal<string | null> = this._accessToken.asReadonly();
  public lastUrlBeforeLogin: string | null = null;

  constructor(
    private authStore: Store<AuthState>,
    private authService: AuthenticationService
  ) {}

  getTenantShortCode(): string | null {
    return this.tenantShortCode;
  }

  getTenantKey(): string | null {
    return this.tenantKey;
  }

  setTenantKey(tenantKey: string | null): string | null {
    this.tenantKey = tenantKey;
    return this.tenantKey;
  }

  getUserPrincipalFromAccessToken(): JwtUserPrincipal | null {
    const decodedUserAccessToken = jwtDecode<JwtPayload>(this.accessToken());
    return JSON.parse(atob(decodedUserAccessToken.principal)) as JwtUserPrincipal;
  }
  /**
   * Tries to read the access token from the localStorage
   *
   * @returns {(string | null)} The access token if available otherwise null
   */
  getAccessToken(): string | null {
    const storedToken = localStorage.getItem(`dispo.access_token`);
    if (storedToken) {
      if (this._accessToken() !== storedToken) {
        this._accessToken.set(storedToken);
        this.grantsService.setGrants(storedToken);
      }
    }
    return localStorage.getItem(`dispo.access_token`);
  }

  /**
   * Tries to read the refresh token from the localStorage
   *
   * @returns {(string | null)} The refresh token if available otherwise null
   */
  getRefreshToken(): string | null {
    return localStorage.getItem(`dispo.refresh_token`);
  }

  /**
   * Tries to read the access token id from the localStorage
   *
   * @returns {(string | null)} The access token id if available otherwise null
   */
  getTokenId(): string | null {
    return localStorage.getItem(`dispo.access_token_id`);
  }

  /**
   * Sends the login form values to the server and tries to login the user
   * - It sets the auth store status state to pending
   * - If the login was successfull dipatches a login action to the store
   * - Otherwise sets the error object and the status
   *
   * @param {UsernameLoginRequest} formValues The login form values an email and a password
   * @return {void} Nothing
   */
  login(formValues: UsernameLoginRequest): void {
    this.authStore.dispatch(new SetStatus({ status: Status.Pending }));
    this.authService
      .authUser({
        body: { ...formValues },
      })
      .subscribe({
        next: (response) => {
          if (
            response &&
            response.access_token &&
            response.access_token.valid_until &&
            response.access_token.value &&
            response.refresh_token &&
            response.refresh_token.valid_until &&
            response.refresh_token.value &&
            response.id
          ) {
            this.authStore.dispatch(
              new Login({ tokens: response as LoginResponse, refresh: true })
            );
          } else {
            this.authStore.dispatch(
              new SetStatus({
                status: Status.Failed,
                error: {
                  args: [],
                  code: '000000',
                  message: 'Invalid response',
                  time: Date.now.toString(),
                  title: 'Error',
                },
              })
            );
          }
        },
        error: (error) => {
          console.error(`[AuthService][login]`, error);
          this.authStore.dispatch(new SetStatus({ status: Status.Failed, error: error.error }));
        },
      });
  }

  /**
   * Sends the login form values to the server and tries to login the user
   * - It sets the auth store status state to pending
   * - If the login was successfull dipatches a login action to the store
   * - Otherwise sets the error object and the status
   *
   * @param {OneTimeCodeLoginRequest} formValues The login form values a phonenumber and otc
   * @return {void} Nothing
   */
  loginWithPhone(formValues: OneTimeCodeLoginRequest): void {
    this.authStore.dispatch(new SetStatus({ status: Status.Pending }));
    this.authService
      .authUserWithPhoneNumberVerify({
        body: { ...formValues },
      })
      .subscribe({
        next: (response) => {
          if (
            response &&
            response.access_token &&
            response.access_token.valid_until &&
            response.access_token.value &&
            response.refresh_token &&
            response.refresh_token.valid_until &&
            response.refresh_token.value &&
            response.id
          ) {
            this.authStore.dispatch(
              new Login({ tokens: response as LoginResponse, refresh: true })
            );
          } else {
            this.authStore.dispatch(
              new SetStatus({
                status: Status.Failed,
                error: {
                  args: [],
                  code: '000000',
                  message: 'Invalid response',
                  time: Date.now.toString(),
                  title: 'Error',
                },
              })
            );
          }
        },
        error: (error) => {
          console.error(`[AuthService][login]`, error);
          this.authStore.dispatch(new SetStatus({ status: Status.Failed, error: error.error }));
        },
      });
  }

  /**
   * Sends a logout action to the auth store
   * @return {void} Nothing
   */
  logout(): void {
    this.authStore.dispatch(new Logout());
  }

  /**
   * Tries to refresh the access token by sending a refresh request to the server
   * with a refresh token when it's available
   *
   * @returns {(Observable<LoginResponse | string>)} A Observable response from the server with a login response
   * or with an error
   */
  refreshAccessToken(): Observable<AuthUserResponse | string> {
    const refreshToken = this.getRefreshToken();
    if (refreshToken) {
      return this.authService.authUser({
        body: { refresh_token: refreshToken },
      });
    } else {
      return of('error: no token in the storage');
    }
  }

  /**
   * Sends a password change request to the server with the given email address
   *
   * @param {{ email: string }} form the email address of the user whom we want to change the password
   * @returns {Observable<any>} for security reasons the server always gives back and empty response
   */
  resetPassword(body: { email: string }): Observable<any> {
    return this.authService.requestPasswordReset({ body });
  }

  /**
   * Sends the new password to the server to change the oldone for the user
   *
   * @param {{ token: string; password: string }} body the token for the password change given by the server in the url
   * @returns {Observable<LoginResponse>} A Observable response from the server with a login response
   */
  changePassword(body: { token: string; password: string }): Observable<AuthUserResponse> {
    return this.authService.setPassword({ body });
  }

  /**
   * Sends a token to a server for validation for the password change
   *
   * @param {string} token The token what we want to validate
   * @returns {Observable<any>} A empty Observable response from the server when the token is valid
   * or an error when it is not
   */
  validateToken(token: string): Observable<any> {
    return this.authService.validateToken({ body: { token } });
  }
}
