<button
  *ngIf="options.closeButton"
  (click)="remove()"
  type="button"
  class="toast-close-button"
  aria-label="Close">
  <span aria-hidden="true">
    <i class="pi pi-times"></i>
  </span>
</button>

<div class="toast-wrapper" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="icon-wrapper">
    <i class="pi"></i>
  </div>
  <div class="content-wrapper">
    <!-- START OF SIMPLE TOASTS -->
    <ng-container *ngIf="!options.payload?.custom; else custom">
      <div class="toast-title" *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
        {{ title }}
      </div>
      <div class="toast-message-details">
        <div
          *ngIf="message && options.enableHtml"
          role="alert"
          [class]="options.messageClass"
          [innerHTML]="message"></div>
        <div
          *ngIf="message && !options.enableHtml"
          role="alert"
          [class]="options.messageClass"
          [attr.aria-label]="message">
          {{ message }}
        </div>
      </div>
    </ng-container>

    <!-- START OF CUSTOM TOASTS -->
    <ng-template #custom>
      <!-- CASE COMMENT CREATED TOAST -->
      <ng-container *ngIf="options.payload?.type === 'comment-created'">
        <div class="toast-title" [class]="options.titleClass" [attr.aria-label]="title">
          {{ 'TOAST.COMMENT_CREATION.TITLE' | translate }}
        </div>
        <div class="toast-message-details">
          {{ 'TOAST.COMMENT_CREATION.CASE_WITH_ID' | translate }}
          <span class="link" (click)="navigateTo('fnrl/cases/', options.payload?.caseId)">
            {{ options.payload?.caseNaturalId }}
            <i class="pi pi-external-link"></i>
          </span>
          {{ 'TOAST.COMMENT_CREATION.CASE_RECEIVE_COMMENT' | translate }}
        </div>
      </ng-container>

      <!-- TOUR UPDATED TOAST -->
      <ng-container *ngIf="options.payload?.type === 'tour-updated'">
        <div class="toast-title" [class]="options.titleClass" [attr.aria-label]="title">
          {{ 'TOAST.TOUR_STATE_CHANGED.TITLE' | translate }}
        </div>
        <div class="toast-message-details">
          {{ 'TOAST.TOUR_STATE_CHANGED.TOUR' | translate }}
          <span class="font-bold">
            {{ options.payload?.tourNaturalId }}
            <i class="pi pi-external-link"></i>
          </span>
          {{ 'TOAST.TOUR_STATE_CHANGED.DESCRIPTION' | translate }}
        </div>
      </ng-container>

      <!-- TOUR CREATED TOAST -->
      <ng-container *ngIf="options.payload?.type === 'tour-created'">
        <div class="toast-title" [class]="options.titleClass" [attr.aria-label]="title">
          {{ 'TOAST.TOUR_CREATE.TITLE' | translate }}
        </div>
        <div class="toast-message-details">
          {{ 'TOAST.TOUR_CREATE.TOUR' | translate }}
          <span class="font-bold">
            {{ options.payload?.tourNaturalId }}
            <i class="pi pi-external-link"></i>
          </span>
          {{ 'TOAST.TOUR_CREATE.DESCRIPTION' | translate }}
        </div>
      </ng-container>

      <!-- NEW CASE ACTION TOAST -->
      <ng-container *ngIf="options.payload?.type === 'case-action'">
        <div class="toast-title" [class]="options.titleClass" [attr.aria-label]="title">
          {{ 'TOAST.CASE_ACTION.TITLE' | translate }}
        </div>
        <div class="toast-message-details">
          <span class="link" (click)="navigateTo('fnrl/cases/', options.payload?.caseId)">
            {{ options.payload?.caseNaturalId }}
            <i class="pi pi-external-link"></i>
            &nbsp;
          </span>
          <span class="action">{{ options.payload?.caseAction }}</span>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>

<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
