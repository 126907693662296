import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { StoreService } from '../services/store.service';

@Injectable({
  providedIn: 'root',
})
class PermissionsService {
  constructor(
    private auth: AuthService,
    private storeService: StoreService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (this.auth.getAccessToken() && this.auth.getRefreshToken()) {
      const route = this.storeService.defaultRoute();
      this.router.navigateByUrl(route);
      return false;
    }
    return true;
  }
}

export const AntiAuthGuard: CanActivateFn = () => {
  return inject(PermissionsService).canActivate();
};
